import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`1/4 depth Barbell Jump Squats 3×6\\@70% 1RM`}</p>
    <p>{`superset with`}</p>
    <p>{`Back Squats 3×6\\@70% 1RM`}</p>
    <p>{`Hamstring Curls 3×10`}</p>
    <p>{`then,`}</p>
    <p>{`For time:`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`20 Box Jumps (30/24″)`}</p>
    <p>{`20 T2B`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`20/Leg DB Step Ups (24/20″, 35/20’s)`}</p>
    <p>{`20 T2B`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`20/Leg DB Reverse Lunges (35/20’s)`}</p>
    <p>{`20 T2B`}</p>
    <p><em parentName="p">{`*`}{`Rx+ = 45/30`}{`#`}{`’s`}</em></p>
    <p><em parentName="p">{`*`}{`Get your team registered for the Granite Games Fall Throwdown here at
The Ville September 14th!  Learn more about the Throwdown here:
 `}<a parentName="em" {...{
          "href": "https://thegranitegames.com/about-throwdowns/"
        }}>{`https://thegranitegames.com/about-throwdowns/`}</a></em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      